import * as React from "react";
import md5 from "md5";
import { Alert2 } from "../helpers/alert2";
import { ResponseBase } from "../models/responseBase";
import { StyleSheet, Button, SafeAreaView, TextInput } from "react-native";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    goButtonEnabled: boolean;
    userName: string;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
}

export default class RegisterScreen extends React.Component<Props, State> {

    state = {
        goButtonEnabled: true,
        userName: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null
    }

    public get isValid() {

        //if (!this.state.firstName) return false;
        //if (!this.state.lastName) return false;
        if (!this.state.email) return false;
        //if (!this.state.userName) return false;
        if (!this.state.password) return false;

        return true;
    }

    public register() {

        let passwordMD5 = md5(this.state.password);

        fetch("https://arcanechapters.com/register", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                userName: this.state.userName,
                password: passwordMD5
            })
        }).then((result) => result.json()).then((result) => {
            this.registerCallback(result);
        }).catch((error) => {
            Alert2.alert(error);
        });
    }

    public registerCallback(result: ResponseBase) {

        if (result.success) {
            Alert2.alert("Register successful. Confirmation email has been sent.");
            this.props.navigation.goBack();
        } else if (result.errorCode) {
            Alert2.alert(result.errorCode);
            this.setState({
                goButtonEnabled: false
            });
        } else {
            Alert2.alert("Unknown error");
            this.setState({
                goButtonEnabled: false
            });
        }
    }

    render() {
        return (
            <SafeAreaView style={styles.container}>
                {/*<TextInput placeholder="First Name" style={styles.input}*/}
                {/*    onChangeText={(text) => {*/}
                {/*        this.setState({*/}
                {/*            firstName: text*/}
                {/*        });*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<TextInput placeholder="Last Name" style={styles.input}*/}
                {/*    onChangeText={(text) => {*/}
                {/*        this.setState({*/}
                {/*            lastName: text*/}
                {/*        });*/}
                {/*    }}*/}
                {/*/>*/}
                <TextInput placeholder="Email Address" style={styles.input} autoCapitalize="none"
                    onChangeText={(text) => {
                        this.setState({
                            email: text
                        });
                    }}
                />
                {/*<TextInput placeholder="User Name" style={styles.input}*/}
                {/*    onChangeText={(text) => {*/}
                {/*        this.setState({*/}
                {/*            userName: text*/}
                {/*        });*/}
                {/*    }}*/}
                {/*/>*/}
                <TextInput placeholder="Password" style={styles.input} secureTextEntry={true} autoCapitalize="none"
                    onChangeText={(text) => {
                        this.setState({
                            password: text,
                        });
                    }}
                />
                <Button title="GO" disabled={!this.isValid}
                    onPress={() => {
                        this.setState({
                            goButtonEnabled: false,
                        });
                        this.register();
                    }}
                />
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    input: {
        padding: 10,
        textAlign: "center"
    },
    container: {
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        justifyContent: "center"
    }
});
