import * as React from "react";
import { Storage } from "../helpers/storage";
import { StyleSheet, Button, SafeAreaView, Text, ScrollView, TextInput, View, TouchableOpacity } from "react-native";
import { BookModel } from "../models/bookModel";
import { BookModelWithChapters } from "../models/bookModelWithChapters";
import { ChapterModel } from "../models/chapterModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { LinkModel } from "../models/linkModel";
import { ResponseBase, ResponseBaseRecord } from "../models/responseBase";
import { ChapterModelWithLinks } from "../models/chapterModelWithLinks";
import { Alert2 } from "../helpers/alert2";
import AuthorBookScreen from "./AuthorBookScreen";
import { LinkModelWithTargetChapterIndex } from "../models/linkModelWithTargetChapterIndex";
import OverviewScreen from "./OverviewScreen";
import AuthorLinkList from "../components/AuthorLinkList";
import { BookOverviewModel } from "../models/bookOverviewModel";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
    bookMoniker: string;
    chapterWithLinks: ChapterModelWithLinks;
    chapterIndex: number;
    chapterTitle: string;
    chapterText: string;
    isSaved: boolean;
    sourceChapterIndex: number,
    sourceLinkIndex: number

}

export default class AuthorChapterScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        isRefreshing: false,
        isInitialRefreshDone: false,
        bookMoniker: this.props.route.params.bookMoniker as string,
        chapterWithLinks: null as ChapterModelWithLinks,
        chapterIndex: this.props.route.params.chapterIndex as number,
        chapterTitle: this.props.route.params.chapterTitle as string,
        chapterText: this.props.route.params.chapterText as string,
        isSaved: true,
        sourceChapterIndex: null as number,
        sourceLinkIndex: null as number
    }

    public saveTimeout: any = null;
    public static refresh: Function;

    public get isValid() {

        if (!this.state.chapterTitle) return false;
        if (!this.state.chapterText) return false;

        return true;
    }



    constructor(props: Props) {

        super(props);

        this.updateTitle();

    }



    componentDidMount() {

        console.log("[AuthorChapterScreen] componentDidMount");

        AuthorChapterScreen.refresh = (newState: any) => {

            console.log("[AuthorChapterScreen] refresh", newState);

            if (newState) {
                this.setState(newState);
                if (newState.chapterIndex) {
                    this.refreshData(newState.chapterIndex);
                }

            }
            else {
                this.refreshData();
            }

        }

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {

        console.log("[AuthorChapterScreen] componentWillUnmount");
        AuthorChapterScreen.refresh = null;
    }

    public delete() {

        if (!this.state.chapterIndex) return;

        Alert2.confirm("Confirm Delete", "Are you sure you want to delete this authored chapter?", "Delete", () => {
            this.deleteConfirmed();
        }, "Cancel", () => {
        });

    }

    public deleteCallback(result: ResponseBase) {

        if (result.success) {

            if (AuthorBookScreen.refresh) {
                AuthorBookScreen.refresh();
            }

            if (OverviewScreen.refresh) {
                OverviewScreen.refresh();
            }
            
            this.props.navigation.goBack();
        }
        else {
            Alert2.alert(result.errorCode);
        }

    }

    public deleteConfirmed() {

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}`;

        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBase) => {
            this.deleteCallback(result);
        });

    }

    public linkItemPressed(link: LinkModelWithTargetChapterIndex) {

        var linkLabel: string = link ? link.label : null;
        var linkIndex: number = link ? link.index : null;
        var linkTargetChapterIndex: number = link ? link.targetChapterIndex : null;

        this.props.navigation.navigate("AuthorLinkScreen", {
            accessToken: this.state.accessToken,
            bookMoniker: this.state.bookMoniker,
            chapterIndex: this.state.chapterIndex,
            linkLabel: linkLabel,
            linkIndex: linkIndex,
            linkTargetChapterIndex: linkTargetChapterIndex
        });

    }

    public preview() {

        let bookOverview: BookOverviewModel = {
            accountBook: null,
            book: {
                hasLinkWithNoTarget: false,
                moniker: this.state.bookMoniker
            },
            unreadOneShotPurchaseCount: 0
        };

        this.props.navigation.navigate("ReadScreen", {
            accessToken: this.state.accessToken,
            bookOverview: bookOverview,
            chapterIndex: this.state.chapterIndex
        });

    }

    public refreshData(chapterIndex?: number) {

        if (!chapterIndex) chapterIndex = this.state.chapterIndex;

        //if (!this.state.chapterIndex) return;
        if (!chapterIndex) return;

        //console.log("[AuthorChapterScreen] refreshData", chapterIndex);

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${chapterIndex}`;

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<ChapterModelWithLinks>) => {
            this.refreshDataCallback(result);
        });

    }

    public refreshDataCallback(result: ResponseBaseRecord<ChapterModelWithLinks>) {

        console.info("[AuthorChapterScreen]", result);

        this.setState({
            isRefreshing: false,
            isInitialRefreshDone: true,
            chapterWithLinks: result.record,
            chapterText: result.record.text,
            chapterIndex: result.record.index,
            chapterTitle: result.record.title
        });

    }

    public save() {

        if (!this.isValid) return;

        console.log("[AuthorChapterScreen] save", this.state);

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = null;
        }

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter`;
        var method = "POST";

        if (this.state.chapterIndex) {
            url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}`;
            method = "PUT";
        }

        fetch(url, {
            method: method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: this.state.accessToken,
                title: this.state.chapterTitle,
                text: this.state.chapterText,
                sourceChapterIndex: this.state.sourceChapterIndex,
                sourceLinkIndex: this.state.sourceLinkIndex
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<ChapterModelWithLinks>) => {
            this.saveCallback(result);
        }).catch((error) => {
            Alert2.alert(error);
        });

    }

    public saveCallback(result: ResponseBaseRecord<ChapterModelWithLinks>) {

        if (result.success) {
            this.setState({
                isSaved: true,
                isInitialRefreshDone: true,
                chapterWithLinks: result.record,
                chapterIndex: result.record.index,
                chapterText: result.record.text,
                chapterTitle: result.record.title
            });
        }

        if (AuthorBookScreen.refresh) {
            AuthorBookScreen.refresh();
        }

        this.updateTitle();

    }

    public updateTitle() {
        if (this.state.chapterTitle) {
            this.props.navigation.setOptions({ title: `Links of '${this.state.chapterTitle}'` });
        }
        else {
            this.props.navigation.setOptions({ title: "Links of new chapter" });
        }
    }

    render() {

        //console.log("[AuthorChapterScreen] render", this.props, this.state);

        if (!this.state.isSaved) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                this.save();
            }, 750);
        }

        return (
            <SafeAreaView style={styles.container}>
                <ScrollView style={styles.scrollView}>
                    <Text style={styles.boldText}>Title:</Text>
                    <TextInput
                        value={this.state.chapterTitle ? this.state.chapterTitle : ""}
                        placeholder="Title"
                        style={[styles.input, styles.chapterTitle]}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onChangeText={(text) => {
                            this.setState({
                                chapterTitle: text,
                                isSaved: false
                            });
                        }}
                    />
                    <Text style={styles.boldText}>Text:</Text>
                    <TextInput
                        value={this.state.chapterText ? this.state.chapterText : ""}
                        multiline
                        placeholder="Text"
                        autoCapitalize="none"
                        textAlignVertical="top"
                        style={[styles.input, styles.chapterText]}
                        onChangeText={(text) => {
                            this.setState({
                                chapterText: text,
                                isSaved: false
                            });
                        }}
                    />
                    <AuthorLinkList items={this.state.chapterWithLinks ? this.state.chapterWithLinks.links : null} onItemPress={(item: LinkModelWithTargetChapterIndex) => {
                        this.linkItemPressed(item);
                    }} />
                </ScrollView>
                <View>
                    <Button title="Save" disabled={this.state.isSaved || !this.isValid}
                        onPress={() => {
                            this.save();
                        }}
                    />
                    <Button title="Preview"
                        onPress={() => {
                            this.preview();
                        }}
                    />
                    <Button title="Delete" disabled={!this.state.chapterIndex}
                        onPress={() => {
                            this.delete();
                        }}
                    />
                </View>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        justifyContent: "center"
    },
    scrollView: {
        flex: 1,
        display: "flex",
        padding: 5
    },
    input: {
        padding: 10,
        textAlign: "left"
    },
    boldText: {
        fontWeight: "bold"
    },
    chapterTitle: {
        borderWidth: 2,
        borderColor: "gray",
        marginBottom: 5
    },
    chapterText: {
        borderWidth: 2,
        borderColor: "gray",
        marginBottom: 5
    },
    linksView: {
    },
    linkItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    linkItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    linkItemNoTarget: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    linkItemLabel: {
        color: "white"
    },
    linkItemLabel_addNew: {

    },
    linkItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});
