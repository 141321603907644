import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";


export type Props = {
    book?: BookModel;
    onPress?: Function;
}

export type State = {
}

export default class Book extends React.Component<Props, State> {

    render() {
        let styleArray: any[] = [styles.bookTitle];

        var title = "[create new book]";
        if (this.props.book) {
            title = this.props.book.title;
            if (this.props.book.hasLinkWithNoTarget) {
                title += " [has link with no target]";
                styleArray.push(styles.bookTitle_invalid);
            }
        }
        else {
            styleArray.push(styles.bookTitle_createNew);
        }

        return (
            <TouchableOpacity style={styles.book} onPress={() => {
                if (this.props.onPress) {
                    this.props.onPress(this.props.book);
                }
            }}>
                <Text style={styleArray}>{title}</Text>
            </TouchableOpacity>
        )
    }

}

const styles = StyleSheet.create({
    book: {
        backgroundColor: "#f4f4f4",
        borderWidth: 2,
        borderColor: "black",
        display: "flex",
        padding: 10,
        margin: 5,
    },
    bookTitle_invalid: {
        color: "red",
        fontWeight: "bold"
    },
    bookTitle_createNew: {
        color: "gray"
    },
    bookTitle: {
        fontSize: 20
    }
});

