import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { AccountBookModel } from "../models/accountBookModel";
import { BookModel } from "../models/bookModel";
import { BookOverviewModel } from "../models/bookOverviewModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { ReadModel } from "../models/readModel";
import ReadIndexButton from "./ReadIndexButton";


export type Props = {
    onButtonPress?: Function,
    bookOverview: BookOverviewModel
}

export type State = {
}

export default class ReadButtonList extends React.Component<Props, State> {

    render() {

        if (!this.props.bookOverview) return null;

        let children: JSX.Element[] = [];
        let key: number = 0;

        if (!this.props.bookOverview.accountBook) {
            if (this.props.bookOverview.book.trialChapterLimit && this.props.bookOverview.book.trialChapterLimit > 0) {

                children.push(<ReadIndexButton
                    iconType={'trial'}
                    key={key}
                    accountBook={this.props.bookOverview.accountBook}
                    onPress={(readIndex: number) => {
                        if (this.props.onButtonPress) {
                            this.props.onButtonPress(readIndex);
                        }
                    }}></ReadIndexButton>);

                key++;
            }
        }
        else {

            var iconType: any = undefined;

            if (this.props.bookOverview.accountBook.plan == 0) iconType = "trial";
            else if (this.props.bookOverview.accountBook.plan == 1) iconType = "oneShot";
            else if (this.props.bookOverview.accountBook.plan == 2) iconType = "unlocked";
            else if (this.props.bookOverview.accountBook.plan == 3) iconType = "unlocked";

            this.props.bookOverview.accountBook.reads.map((readItem: ReadModel, index: number) => {

                children.push(<ReadIndexButton
                    readIndex={readItem.index}
                    currentChapterTitle={readItem.currentChapterTitle}
                    iconType={iconType}
                    isFinished={readItem.isFinished}
                    key={key}
                    accountBook={this.props.bookOverview.accountBook}
                    onPress={(readIndex: number) => {
                        if (this.props.onButtonPress) {
                            this.props.onButtonPress(readIndex);
                        }
                    }}></ReadIndexButton>);

                key++;
            });


            if (this.props.bookOverview.accountBook.plan == 1) {
                if (this.props.bookOverview.unreadOneShotPurchaseCount && this.props.bookOverview.unreadOneShotPurchaseCount > 0) {
                    for (let i = 0; i < this.props.bookOverview.unreadOneShotPurchaseCount; i++) {

                        children.push(<ReadIndexButton
                            iconType={'oneShot'}
                            key={key}
                            accountBook={this.props.bookOverview.accountBook}
                            onPress={(readIndex: number) => {
                                if (this.props.onButtonPress) {
                                    this.props.onButtonPress(readIndex);
                                }
                            }}></ReadIndexButton>);

                        key++;
                    }
                }
            } else if (this.props.bookOverview.accountBook.plan == 2 || this.props.bookOverview.accountBook.plan == 3) {

                children.push(<ReadIndexButton
                    iconType={'unlocked'}
                    key={key}
                    accountBook={this.props.bookOverview.accountBook}
                    onPress={(readIndex: number) => {
                        if (this.props.onButtonPress) {
                            this.props.onButtonPress(readIndex);
                        }
                    }}></ReadIndexButton>);

                key++;
            }

        }

        return <View style={styles.readItemContainer} children={children}></View>;

    }

}

const styles = StyleSheet.create({
    readItemContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 5,
        flex: 1
    },
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

