import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { Picker } from "@react-native-picker/picker";


export type Props = {
    chapters: ChapterModelWithIndex[],
    selectedValue: string,
    onValueChange?: Function
}

export type State = {
}

export default class AuthorTargetChapterPicker extends React.Component<Props, State> {

    render() {

        if (!this.props.chapters) return null;

        return (
            <View style={styles.pickerView}>
                <Text style={styles.boldText}>Target Chapter:</Text>
                <View style={styles.pickerInnerView}>
                    <Picker style={[styles.picker, styles.targetChapterPicker]}
                        selectedValue={this.props.selectedValue}
                        onValueChange={(value) => {
                            //console.log(value);
                            //this.setState({
                            //    linkTargetChapterIndex: (value == "null" ? null : parseInt(value)),
                            //    isSaved: false,
                            //    isCreateNewChapter: (value == "create")
                            //})
                            if (this.props.onValueChange) {
                                this.props.onValueChange(value);
                            }
                        }}>
                        <Picker.Item label="[no target]" value="null" key={0} />
                        {this.props.chapters.map((item, index) => {
                            var label = `${item.index} - ${item.title}`;
                            return (<Picker.Item label={label} value={item.index.toString()} key={item.index + 1} />);
                        })}
                        <Picker.Item label="[create new chapter]" value="create" key={1} />
                    </Picker>
                </View>
            </View>
        )

    }

}

const styles = StyleSheet.create({
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    targetChapterPicker: {
    },
    pickerInnerView: {
        borderWidth: 2,
        borderColor: "gray"
    },
    picker: {
        padding: 10
    },
    boldText: {
        fontWeight: "bold"
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    pickerView: {
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

