import { Platform, Alert } from 'react-native';

class Alert2 {

    public static alert = (message: string, callback?: Function) => {
        if (Platform.OS === 'web') {
            alert(message);
            if (callback) callback();
        } else {
            Alert.alert(message, '', [{
                text: 'OK',
                onPress: () => {
                    if (callback) callback();
                },
            },
            ]);
        }
    };

    public static confirm = (title: string, message: string, okLabel: string, okCallback: Function, cancelLabel: string, cancelCallback: Function) => {

        if (Platform.OS === 'web') {

            if (confirm(message) == true) {
                okCallback();
            } else {
                cancelCallback();
            }

        } else {

            Alert.alert(
                title,
                message,
                [
                    {
                        text: okLabel,
                        onPress: () => okCallback()
                    },
                    {
                        text: cancelLabel,
                        onPress: () => cancelCallback(),
                        style: "cancel"
                    },

                ]
            );

        }

    }



}


export { Alert2 };
