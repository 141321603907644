import * as React from "react";
import { Storage } from "../helpers/storage";
import { BookModel } from "../models/bookModel";
import { OverviewModel } from "../models/overviewModel";
import { ResponseBase, ResponseBaseRecord } from "../models/responseBase";
import { Text, View, StyleSheet, Button, FlatList, SafeAreaView, ScrollView, TouchableOpacity, ActivityIndicator } from "react-native";
import Library from "../components/Library"

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    overview: OverviewModel;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
}

export default class OverviewScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        overview: new OverviewModel(),
        isRefreshing: false,
        isInitialRefreshDone: false
    }

    public static refresh: Function;



    constructor(props: Props) {

        super(props);

        //OverviewScreen.refresh = () => {
        //    this.refreshData();
        //}

        //if (!this.state.isInitialRefreshDone) {
        //    this.refreshData();
        //}
    }



    componentDidMount() {
        console.log("[OverviewScreen] componentDidMount");
        OverviewScreen.refresh = () => {
            this.refreshData();
        }

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {
        console.log("[OverviewScreen] componentWillUnmount");
        OverviewScreen.refresh = null;
    }

    public authorBookModelItemPressed(book: BookModel) {

        var bookMoniker: string = book ? book.moniker : null;
        var bookTitle: string = book ? book.title : null;
        var bookDescription: string = book ? book.description : null;

        this.props.navigation.navigate("AuthorBookScreen", {
            bookMoniker: bookMoniker,
            bookTitle: bookTitle,
            bookDescription: bookDescription,
            accessToken: this.state.accessToken
        });

    }

    public bookModelItemPressed(book: BookModel) {
        this.props.navigation.navigate("BookOverviewScreen", {
            book: book,
            accessToken: this.state.accessToken
        });
    }

    public logout() {
        Storage.clearStorage();
        this.props.navigation.reset({
            index: 0,
            routes: [{ name: "StartScreen" }]
        });
    }

    public refreshData() {

        fetch("https://arcanechapters.com/overview", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<OverviewModel>) => {
            this.refreshDataCallback(result);
        });
    }

    public refreshDataCallback(result: ResponseBaseRecord<OverviewModel>) {

        console.info("[OverviewScreen]", result);

        this.setState({
            isRefreshing: false,
            isInitialRefreshDone: true,
            overview: result.record
        });
    }

    render() {

        //console.log("[OverviewScreen] render", this.state);

        return (
            <SafeAreaView style={styles.safeViewArea}>
                <ScrollView style={styles.scrollView} >
                    <Library
                        label="Library"
                        isLoading={!this.state.isInitialRefreshDone}
                        books={this.state.overview ? this.state.overview.purchasedBooks : []}
                        onPress={(item: BookModel) => { this.bookModelItemPressed(item) }}
                    />
                    <Library
                        label="Recommended"
                        isLoading={!this.state.isInitialRefreshDone}
                        books={this.state.overview ? this.state.overview.recommendedBooks : []}
                        onPress={(item: BookModel) => { this.bookModelItemPressed(item) }} />
                    <Library
                        label="Discount"
                        isLoading={!this.state.isInitialRefreshDone}
                        books={this.state.overview ? this.state.overview.discountedBooks : []}
                        onPress={(item: BookModel) => { this.bookModelItemPressed(item) }} />
                    <Library
                        label="Author"
                        isLoading={!this.state.isInitialRefreshDone}
                        books={this.state.overview ? this.state.overview.authoredBooks : []}
                        onPress={(item: BookModel) => { this.authorBookModelItemPressed(item) }}
                        isAuthorLibrary={true} />
                </ScrollView>
                <View>
                    <Button title="Logout" onPress={() => {
                        this.logout();
                    }} />
                </View>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    safeViewArea: {
        flex: 1,
        width: "100%",
        backgroundColor: "white",
        justifyContent: "center"
    },
    scrollView: {
        flex: 1,
        backgroundColor: "white",
        display: "flex"
    }
});
