import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { LinkModel } from "../models/linkModel";
import Link from "./ReadLink";


export type Props = {
    items: LinkModel[],
    onItemPress?: Function
}

export type State = {
}

export default class ReadLinkList extends React.Component<Props, State> {

    render() {

        let children: JSX.Element[] = [];

        this.props.items.map((item: LinkModel, index: number) => {
            children.push(<Link key={index} item={item} onPress={(item: LinkModel) => {
                if (this.props.onItemPress) {
                    this.props.onItemPress(item);
                }
            }}></Link>);
        });

        return (
            <View>
                <View children={children} />
            </View>
        );
    }

}

const styles = StyleSheet.create({
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

