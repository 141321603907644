import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";


export type Props = {
    onPurchaseOneShotPress?: Function;
    onPurchaseUnlockedPress?: Function;
    onClearAllReadsPress?: Function;
    isScreenRefreshing: boolean;
    currentOneShotPrice: number;
    currentFullPrice: number;
    isPurchaseOneShotButtonVisible: boolean;
    isPurchaseUnlockedButtonVisible: boolean;
    isAuthoredBook?: boolean;
}

export type State = {
}

export default class ReadPurchaseButtons extends React.Component<Props, State> {

    render() {

        return (
            <View>
                {this.props.isAuthoredBook ?
                    (<Button title="Clear All Reads" onPress={() => {
                        if (this.props.onClearAllReadsPress) {
                            this.props.onClearAllReadsPress();
                        }
                    }}>
                    </Button>) : null}
                {this.props.isPurchaseOneShotButtonVisible ?
                    (<Button title={`Purchase For One Shot Read [\$${this.props.currentOneShotPrice}]`}
                        onPress={() => {
                            if (this.props.onPurchaseOneShotPress) {
                                this.props.onPurchaseOneShotPress();
                            }
                        }}
                        disabled={this.props.isScreenRefreshing} />) : null}
                {this.props.isPurchaseUnlockedButtonVisible ?
                    (<Button title={`Purchase Unlocked [\$${this.props.currentFullPrice}]`}
                        onPress={() => {
                            if (this.props.onPurchaseUnlockedPress) {
                                this.props.onPurchaseUnlockedPress();
                            }
                        }}
                        disabled={this.props.isScreenRefreshing} />) : null}
            </View>
        );

    }

}

const styles = StyleSheet.create({
});

