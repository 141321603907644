import { AuthoredBookModel } from './authoredBookModel';
import { BookModel } from './bookModel';

class OverviewModel {
    public purchasedBooks: BookModel[] = [];
    public recommendedBooks: BookModel[] = [];
    public discountedBooks: BookModel[] = [];
    public authoredBooks: AuthoredBookModel[] = [];
}

export { OverviewModel }
