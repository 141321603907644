import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import Book from "./Book";

export type Props = {
    label: string;
    isLoading?: boolean;
    books?: BookModel[];
    onPress?: Function;
    isAuthorLibrary?: boolean;
}

export type State = {
}

export default class Library extends React.Component<Props, State> {

    state = {
    }

    render() {

        //console.log("[Library] render", this.state.isLoading, this.props.isLoading);

        if (this.props.isLoading) {
            return (
                <View>
                    <ActivityIndicator size="large" color="green" />
                </View>
            )
        }

        return (
            <View style={styles.view}>
                <Text style={styles.label}>{this.props.label}</Text>
                <View style={styles.innerView}>

                    {this.props.isAuthorLibrary ?
                        <View>
                            <Book key={0} onPress={() => {
                                this.props.onPress();
                            }}></Book>
                        </View>
                        : null}

                    {this.props.books ? this.props.books.map((item: BookModel, index: number) => {
                        return (<Book book={item} key={index} onPress={() => {
                            this.props.onPress(item);
                        }}></Book>)
                    }) : null}

                </View>
            </View>
        );
    }

}

const styles = StyleSheet.create({
    input: {
        padding: 10,
        textAlign: "center"
    },
    view: {
        borderColor: "black",
        borderWidth: 2,
        margin: 5
    },
    label: {
        backgroundColor: "black",
        color: "white",
        padding: 5
    },
    innerView: {
        backgroundColor: "#c4a484",
        minHeight: 30
    },
    book: {
        backgroundColor: "#f4f4f4",
        borderWidth: 2,
        borderColor: "black",
        display: "flex",
        padding: 10,
        margin: 5,
    },
    bookTitle_invalid: {
        color: "red",
        fontWeight: "bold"
    },
    bookTitle_createNew: {
        color: "gray"
    },
    bookTitle: {
        fontSize: 20
    }
});
