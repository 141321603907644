import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { CurrentReadModel } from "../models/currentReadModel";
import { LinkModel } from "../models/linkModel";
import ReadLinkList from "./ReadLinkList";


export type Props = {
    currentReadModel: CurrentReadModel,
    onLinkPress?: Function
}

export type State = {
}

export default class ReadContent extends React.Component<Props, State> {

    render() {

        if (!this.props.currentReadModel) return null;

        return (
            <View style={styles.view}>
                <ScrollView style={styles.scrollView}>
                    <Text style={styles.readContentText}>{this.props.currentReadModel.chapter.text}</Text>
                    <ReadLinkList
                        items={this.props.currentReadModel.chapter.links}
                        onItemPress={(item: LinkModel) => {
                            if (this.props.onLinkPress) {
                                this.props.onLinkPress(item);
                            }
                        }} />
                </ScrollView>
            </View>
        );

    }

}

const styles = StyleSheet.create({
    readContentText: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        padding: 10,
        fontSize: 18,
        textAlign: "justify"
    },
    view: {
        flex: 1,
    },
    scrollView: {
        flex: 1,
        display: "flex",
        backgroundColor: "rgb(196, 164, 132)"
    },
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

