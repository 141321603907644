import * as React from "react";
import { Storage } from "../helpers/storage";
import { StyleSheet, Button, SafeAreaView } from "react-native";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
}

export default class StartScreen extends React.Component<Props, State> {



    constructor(props: any) {

        super(props);

        Storage.getData("accessToken").then((result: any) => {
            if (result) {

                this.setState({
                    accessToken: result
                });

                this.props.navigation.reset({
                    index: 0,
                    routes: [{
                        name: "OverviewScreen", params: {
                            accessToken: result
                        }
                    }]
                });
            }
        });
    }



    render() {
        return (
            <SafeAreaView style={styles.container}>
                <Button title="Login" onPress={() => { this.props.navigation.navigate("LoginScreen") }} />
                <Button title="Register" onPress={() => { this.props.navigation.navigate("RegisterScreen") }} />
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        justifyContent: "center"
    }
});
