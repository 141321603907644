import * as React from "react";
import { Storage } from "../helpers/storage";
import { StyleSheet, Button, SafeAreaView, Text, ScrollView, TextInput, View } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { BookModel } from "../models/bookModel";
import { BookModelWithChapters } from "../models/bookModelWithChapters";
import { ChapterModel } from "../models/chapterModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { LinkModel } from "../models/linkModel";
import { ResponseBase, ResponseBaseRecord } from "../models/responseBase";
import { Alert2 } from "../helpers/alert2";
import AuthorChapterScreen from "./AuthorChapterScreen";
import { LinkModelWithTargetChapterIndex } from "../models/linkModelWithTargetChapterIndex";
import { LinkModelWithTargetChapterIndexAndChapters } from "../models/linkModelWithTargetChapterIndexAndChapters";
import AuthorBookScreen from "./AuthorBookScreen";
import OverviewScreen from "./OverviewScreen";
import AuthorTargetChapterPicker from "../components/AuthorTargetChapterPicker";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
    isSaved: boolean;
    linkWithTargetChapterIndex: LinkModelWithTargetChapterIndex;
    bookMoniker: string;
    chapterIndex: number;
    linkIndex: number;
    linkLabel: string;
    linkTargetChapterIndex?: number;
    isCreateNewChapter: boolean;
    chapters: ChapterModelWithIndex[];
}

export default class AuthorLinkScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        isRefreshing: false,
        isInitialRefreshDone: false,
        isSaved: true,
        linkWithTargetChapterIndex: null as LinkModelWithTargetChapterIndex,
        bookMoniker: this.props.route.params.bookMoniker as string,
        chapterIndex: this.props.route.params.chapterIndex as number,
        linkIndex: this.props.route.params.linkIndex as number,
        linkLabel: this.props.route.params.linkLabel as string,
        linkTargetChapterIndex: this.props.route.params.linkTargetChapterIndex as number,
        isCreateNewChapter: false,
        chapters: null as ChapterModelWithIndex[]
    }

    public saveTimeout: any = null;
    public static refresh: Function;

    public get isValid() {

        if (!this.state.linkLabel) return false;
        if (this.state.isCreateNewChapter) return false;

        return true;
    }
    public get linkTargetPickerSelectedValue(): string {

        if (this.state.isCreateNewChapter) return "create";
        if (!this.state.linkTargetChapterIndex) return "null"

        return this.state.linkTargetChapterIndex.toString();
    }



    constructor(props: Props) {

        super(props);

        //AuthorLinkScreen.refresh = () => {
        //    this.refreshData();
        //}

        //if (!this.state.isInitialRefreshDone) {
        //    this.refreshData();
        //}
    }



    componentDidMount() {

        console.log("[AuthorLinkScreen] componentDidMount");

        AuthorLinkScreen.refresh = () => {
            this.refreshData();
        }

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {

        console.log("[AuthorLinkScreen] componentWillUnmount");
        AuthorLinkScreen.refresh = null;
    }

    public delete() {

        if (!this.state.linkIndex) return;

        Alert2.confirm("Confirm Delete", "Are you sure you want to delete this authored link?", "Delete", () => {
            this.deleteConfirmed();
        }, "Cancel", () => {
        });

    }

    public deleteCallback(result: ResponseBase) {

        if (result.success) {
            AuthorChapterScreen.refresh();
            AuthorBookScreen.refresh();
            OverviewScreen.refresh();
            this.props.navigation.goBack();
        }
        else {
            Alert2.alert(result.errorCode);
        }

    }

    public deleteConfirmed() {

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}/link/${this.state.linkIndex}`;

        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBase) => {
            this.deleteCallback(result);
        });

    }

    public follow() {

        if (this.state.isCreateNewChapter) {

            AuthorChapterScreen.refresh({
                chapterText: "",
                chapterTitle: "",
                chapterIndex: "",
                chapterWithLinks: null,
                sourceChapterIndex: this.state.chapterIndex,
                sourceLinkIndex: this.state.linkIndex
            });

            this.props.navigation.navigate("AuthorChapterScreen");
            return;
        }

        var selectedChapter = this.state.chapters.find(q => q.index == this.state.linkTargetChapterIndex);
        if (!selectedChapter) return;

        //console.log(selectedChapter, this.state.linkTargetChapterIndex);

        AuthorChapterScreen.refresh({
            chapterIndex: this.state.linkTargetChapterIndex,
            chapterText: selectedChapter.text,
            chapterTitle: selectedChapter.title,
            chapterWithLinks: null
        });

        this.props.navigation.navigate("AuthorChapterScreen");

    }

    public refreshData() {

        if (!this.state.linkIndex) return;

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}/link/${this.state.linkIndex}`;

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<LinkModelWithTargetChapterIndexAndChapters>) => {
            this.refreshDataCallback(result);
        });

    }

    public refreshDataCallback(result: ResponseBaseRecord<LinkModelWithTargetChapterIndexAndChapters>) {

        console.info("[AuthorLinkScreen]", result);

        this.setState({
            isRefreshing: false,
            isInitialRefreshDone: true,
            linkWithTargetChapterIndex: result.record,
            linkIndex: result.record.index,
            linkLabel: result.record.label,
            linkTargetChapterIndex: result.record.targetChapterIndex,
            chapters: result.record.chapters
        });

    }

    public save() {

        if (!this.isValid) return;

        console.log("[AuthorLinkScreen] save", this.state);

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = null;
        }

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}/link`;
        var method = "POST";

        if (this.state.linkIndex) {
            url = `https://arcanechapters.com/author/${this.state.bookMoniker}/chapter/${this.state.chapterIndex}/link/${this.state.linkIndex}`;
            method = "PUT";
        }

        fetch(url, {
            method: method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: this.state.accessToken,
                label: this.state.linkLabel,
                targetChapterIndex: this.state.linkTargetChapterIndex
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<LinkModelWithTargetChapterIndexAndChapters>) => {
            this.saveCallback(result);
        }).catch((error) => {
            Alert2.alert(error);
        });

    }

    public saveCallback(result: ResponseBaseRecord<LinkModelWithTargetChapterIndexAndChapters>) {

        if (result.success) {
            this.setState({
                isSaved: true,
                isInitialRefreshDone: true,
                linkLabel: result.record.label,
                linkIndex: result.record.index,
                chapters: result.record.chapters
            });
        }

        AuthorChapterScreen.refresh();
        AuthorBookScreen.refresh();
        OverviewScreen.refresh();

    }

    public targetChapterPickerValueChanged(value: string) {

        this.setState({
            linkTargetChapterIndex: (value == "null" ? null : parseInt(value)),
            isSaved: false,
            isCreateNewChapter: (value == "create")
        });

    }

    render() {

        if (!this.state.isSaved) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                this.save();
            }, 750);
        }

        return (
            <SafeAreaView style={styles.container}>
                <ScrollView style={styles.scrollView}>
                    <Text style={styles.boldText}>Label:</Text>
                    <TextInput
                        value={this.state.linkLabel ? this.state.linkLabel : ""}
                        placeholder="Text"
                        autoCapitalize="none"
                        autoCorrect={false}
                        style={[styles.input, styles.linkLabel]}
                        onChangeText={(text) => {
                            this.setState({
                                linkLabel: text,
                                isSaved: false
                            });
                        }}
                    />
                    <AuthorTargetChapterPicker chapters={this.state.chapters}
                        selectedValue={this.linkTargetPickerSelectedValue} onValueChange={(value: string) => {
                            this.targetChapterPickerValueChanged(value);
                        }} />
                </ScrollView>
                <View>
                    <Button title="Follow" disabled={!this.state.linkTargetChapterIndex && !this.state.isCreateNewChapter}
                        onPress={() => {
                            this.follow();
                        }}
                    />
                    <Button title="Delete" disabled={!this.state.linkIndex}
                        onPress={() => {
                            this.delete();
                        }}
                    />
                    <Button title="Save" disabled={this.state.isSaved || !this.isValid}
                        onPress={() => {
                            this.save();
                        }}
                    />
                </View>


            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        justifyContent: "center"
    },
    linkLabel: {
        borderWidth: 2,
        borderColor: "gray",
        marginBottom: 5
    },
    scrollView: {
        flex: 1,
        padding: 5
    },
    input: {
        padding: 10
    },
    links: {
        padding: 10,
    },
    boldText: {
        fontWeight: "bold"
    },
    linkItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    pickerView: {
    },
    targetChapterPicker: {
    },
    pickerInnerView: {
        borderWidth: 2,
        borderColor: "gray"
    },
    linkItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    picker: {
        padding: 10
    }
});
