import * as React from "react";
import md5 from "md5";
import { Storage } from "../helpers/storage";
import { Alert2 } from "../helpers/alert2";
import { StyleSheet, Button, SafeAreaView, TextInput } from "react-native";
import { ResponseBase, ResponseBaseRecord } from "../models/responseBase";
import { AccessTokenModel } from "../models/accessTokenModel";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    goButtonEnabled: boolean;
    email: string;
    password: string;
}

export default class LoginScreen extends React.Component<Props, State> {

    state = {
        goButtonEnabled: true,
        email: null,
        password: null
    }

    public login() {

        let passwordMD5 = md5(this.state.password);

        fetch("https://arcanechapters.com/login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.email,
                password: passwordMD5
            })
        }).then((response) => response.json()).then((response: ResponseBaseRecord<AccessTokenModel>) => {
            this.loginCallback(response);
        }).catch((error) => {
            Alert2.alert(error);
        });
    }

    public loginCallback(response: ResponseBaseRecord<AccessTokenModel>) {

        if (response.errorCode) {
            Storage.clearStorage();
            Alert2.alert(response.errorCode, () => {
                this.setState({
                    goButtonEnabled: true
                });
            });
        } else if (response && response.record && response.record.token) {

            Storage.storeData("accessToken", response.record.token);
            Storage.storeData("userName", this.state.userName);
            Storage.storeData("password", this.state.password);

            this.props.navigation.reset({
                index: 0,
                routes: [{
                    name: "OverviewScreen",
                    params: { accessToken: response.record.token }
                }]
            });
        } else {
            Storage.clearStorage();
            Alert2.alert("Unknown Error");
            this.setState({
                goButtonEnabled: true
            });
        }
    }

    render() {
        return (
            <SafeAreaView style={styles.container}>
                <TextInput placeholder="Email Address" style={styles.input} autoCapitalize="none"
                    onChangeText={(text) => {
                        this.setState({
                            email: text
                        });
                    }}
                />
                <TextInput placeholder="Password" style={styles.input} secureTextEntry={true} autoCapitalize="none"
                    onChangeText={(text) => {
                        this.setState({
                            password: text,
                        });
                    }}
                />
                <Button title="GO" disabled={!this.state.goButtonEnabled}
                    onPress={() => {
                        this.setState({
                            goButtonEnabled: false
                        });
                        this.login();
                    }}
                />
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    input: {
        padding: 10,
        textAlign: "center"
    },
    container: {
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        justifyContent: "center"
    }
});
