import * as React from "react";
import { Storage } from "../helpers/storage";
import { StyleSheet, Button, SafeAreaView, Text, ScrollView, TextInput, View, TouchableOpacity, Alert } from "react-native";
import { BookModel } from "../models/bookModel";
import { BookModelWithChapters } from "../models/bookModelWithChapters";
import { ChapterModel } from "../models/chapterModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { BookOverviewModel } from "../models/bookOverviewModel";
import { Alert2 } from "../helpers/alert2";
import { ResponseBase, ResponseBaseRecord } from "../models/responseBase";
import OverviewScreen from "./OverviewScreen";
import AuthorChapter from "../components/AuthorChapter";
import AuthorChapterList from "../components/AuthorChapterList";

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
    bookMoniker: string;
    bookWithChapters: BookModelWithChapters;
    bookTitle: string;
    bookDescription: string;
    isSaved: boolean;
}

export default class AuthorBookScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        isRefreshing: false,
        isInitialRefreshDone: false,
        bookMoniker: this.props.route.params.bookMoniker as string,
        bookWithChapters: null as BookModelWithChapters,
        bookTitle: this.props.route.params.bookTitle as string,
        bookDescription: this.props.route.params.bookDescription as string,
        isSaved: true
    }

    public saveTimeout: any = null;
    public static refresh: Function;

    public get isPreviewable(): boolean {

        if (!this.state.bookMoniker) return false;
        if (!this.state.bookWithChapters) return false;
        if (!this.state.bookWithChapters.chapters) return false;
        if (this.state.bookWithChapters.chapters.length == 0) return false;

        return true;
    }
    public get isPublishable(): boolean {

        if (!this.state.bookMoniker) return false;
        if (!this.state.bookWithChapters) return false;

        var forEachResult: boolean = true;

        this.state.bookWithChapters.chapters.forEach(c => {
            if (c.hasLinkWithNoTarget) forEachResult = false;
        });


        return forEachResult;
    }
    public get isValid() {

        if (!this.state.bookTitle) return false;
        if (!this.state.bookDescription) return false;

        return true;
    }



    constructor(props: Props) {

        super(props);

        //AuthorBookScreen.refresh = () => {
        //    this.refreshData();
        //}

        //if (!this.state.isInitialRefreshDone) {
        //    this.refreshData();
        //}

        this.updateTitle();

    }



    componentDidMount() {

        console.log("[AuthorBookScreen] componentDidMount");
        AuthorBookScreen.refresh = () => {
            this.refreshData();
        }

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {
        console.log("[AuthorBookScreen] componentWillUnmount");
        AuthorBookScreen.refresh = null;
    }

    public chapterItemPressed(chapter: ChapterModelWithIndex) {

        var chapterIndex: number = chapter ? chapter.index : null;
        var chapterText: string = chapter ? chapter.text : null;
        var chapterTitle: string = chapter ? chapter.title : null;

        this.props.navigation.navigate("AuthorChapterScreen", {
            accessToken: this.state.accessToken,
            bookMoniker: this.state.bookMoniker,
            chapterIndex: chapterIndex,
            chapterText: chapterText,
            chapterTitle: chapterTitle
        });

    }

    public delete() {

        if (!this.state.bookMoniker) return;

        Alert2.confirm("Confirm Delete", "Are you sure you want to delete this authored book?", "Delete", () => {
            this.deleteConfirmed();
        }, "Cancel", () => {
        });

    }

    public deleteCallback(result: ResponseBase) {

        if (result.success) {

            if (OverviewScreen.refresh) {
                OverviewScreen.refresh();
            }
            
            this.props.navigation.goBack();
        }
        else {
            Alert2.alert(result.errorCode);
        }

    }

    public deleteConfirmed() {

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}`;

        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBase) => {
            this.deleteCallback(result);
        });

    }

    public preview() {

        let bookOverview: BookOverviewModel = {
            accountBook: null,
            book: {
                hasLinkWithNoTarget: false,
                moniker: this.state.bookMoniker
            },
            unreadOneShotPurchaseCount: 0
        };

        this.props.navigation.navigate("ReadScreen", {
            accessToken: this.state.accessToken,
            bookOverview: bookOverview
        });

    }

    public publish() {

        if (!this.state.bookMoniker) return;

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}/publish`;

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBase) => {
            this.publishCallback(result);
        });

    }

    public publishCallback(response: ResponseBase) {

        if (response.success) {

            if (OverviewScreen.refresh) {
                OverviewScreen.refresh();
            }
            
            this.props.navigation.goBack();
        }
        else {
            Alert2.alert(response.errorCode);
        }


    }

    public refreshData() {

        if (!this.state.bookMoniker) return;

        var url = `https://arcanechapters.com/author/${this.state.bookMoniker}`;

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<BookModelWithChapters>) => {
            this.refreshDataCallback(result);
        });

    }

    public refreshDataCallback(result: ResponseBaseRecord<BookModelWithChapters>) {

        console.info("[AuthorBookScreen]", result);

        this.setState({
            isRefreshing: false,
            isInitialRefreshDone: true,
            bookWithChapters: result.record,
            bookMoniker: result.record.moniker,
            bookDescription: result.record.description,
            bookTitle: result.record.title
        });
    }

    public save() {

        if (!this.isValid) return;

        console.log("[AuthorBookScreen] save", this.state);

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = null;
        }

        var url = "https://arcanechapters.com/author";
        var method = "POST";

        if (this.state.bookMoniker) {
            url = `https://arcanechapters.com/author/${this.state.bookMoniker}`;
            method = "PUT";
        }

        fetch(url, {
            method: method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: this.state.accessToken,
                title: this.state.bookTitle,
                description: this.state.bookDescription
            })
        }).then((result) => result.json()).then((result: ResponseBaseRecord<BookModelWithChapters>) => {
            this.saveCallback(result);
        }).catch((error) => {
            Alert2.alert(error);
        });

    }

    public saveCallback(result: ResponseBaseRecord<BookModelWithChapters>) {

        this.setState({
            isSaved: true,
            isInitialRefreshDone: true,
            bookWithChapters: result.record,
            bookMoniker: result.record.moniker,
            bookDescription: result.record.description,
            bookTitle: result.record.title
        });

        if (OverviewScreen.refresh) {
            OverviewScreen.refresh();
        }

        this.updateTitle();

    }

    public updateTitle() {

        if (this.state.bookTitle) {
            this.props.navigation.setOptions({ title: `Chapters of '${this.state.bookTitle}'` });
        }
        else {
            this.props.navigation.setOptions({ title: "Chapters of new book" });
        }

    }

    render() {

        if (!this.state.isSaved) {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                this.save();
            }, 750);
        }

        return (
            <SafeAreaView style={styles.container}>
                <ScrollView style={styles.scrollView}>
                    <Text style={styles.boldText}>Title:</Text>
                    <TextInput
                        value={this.state.bookTitle ? this.state.bookTitle : ""}
                        placeholder="Title"
                        autoCapitalize="none"
                        autoCorrect={false}
                        style={[styles.input, styles.bookTitle]}
                        onChangeText={(text) => {
                            this.setState({
                                bookTitle: text,
                                isSaved: false
                            });
                        }}
                    />
                    <Text style={styles.boldText}>Description:</Text>
                    <TextInput
                        value={this.state.bookDescription ? this.state.bookDescription : ""}
                        placeholder="Description"
                        autoCapitalize="none"
                        autoCorrect={false}
                        multiline
                        numberOfLines={5}
                        textAlignVertical="top"
                        style={[styles.input, styles.bookDescription]}
                        onChangeText={(text) => {
                            this.setState({
                                bookDescription: text,
                                isSaved: false
                            });
                        }}
                    />
                    <AuthorChapterList items={this.state.bookWithChapters ? this.state.bookWithChapters.chapters : null} onItemPress={(item: ChapterModelWithIndex) => {
                        this.chapterItemPressed(item);
                    }} />
                </ScrollView>
                <View>
                    <Button title="Save" disabled={this.state.isSaved || !this.isValid}
                        onPress={() => {
                            this.save();
                        }}
                    />
                    <Button title="Preview" disabled={!this.isPreviewable}
                        onPress={() => {
                            this.preview();
                        }}
                    />
                    <Button title="Delete" disabled={!this.state.bookMoniker}
                        onPress={() => {
                            this.delete();
                        }}
                    />
                </View>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        display: "flex"
    },
    scrollView: {
        flex: 1,
        padding: 5,
        display: "flex"
    },
    input: {
        padding: 10
    },
    chapters: {

    },
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    boldText: {
        fontWeight: "bold"
    },
    bookTitle: {
        borderWidth: 2,
        borderColor: "gray",
        marginBottom: 5
    },
    bookDescription: {
        borderWidth: 2,
        borderColor: "gray",
        marginBottom: 5
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});
