import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";


export type Props = {
    item?: ChapterModelWithIndex,
    key: number,
    isAddNewItem?: boolean,
    onPress?: Function
}

export type State = {
}

export default class AuthorChapter extends React.Component<Props, State> {

    render() {

        let styleArray: any[] = [];
        let labelStyleArray: any[] = [styles.chapterItemLabel];

        let label = "[add new chapter]"
        styleArray.push(styles.chapterItem);

        if (!this.props.isAddNewItem && this.props.item) {
            label = `${this.props.item.index} - ${this.props.item.title}`;
            if (this.props.item.hasLinkWithNoTarget) {
                label += " [has link with no target]";
                labelStyleArray.push(styles.chapterItemLabel_invalid);
            }
        }
        else {
            styleArray.push(styles.chapterItemAddNew);
            labelStyleArray.push(styles.chapterItemLabel_addNew);
        }

        return (
            <TouchableOpacity style={styleArray} onPress={() => {
                if (this.props.onPress) {
                    this.props.onPress(this.props.item);
                }
            }}>
                <Text style={labelStyleArray}>{label}</Text>
            </TouchableOpacity>
        );

    }

}

const styles = StyleSheet.create({
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

