import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import StartScreen from "./screens/StartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import BookOverviewScreen from "./screens/BookOverviewScreen";
import OverviewScreen from "./screens/OverviewScreen";
import ReadScreen from "./screens/ReadScreen";
import AuthorBookScreen from "./screens/AuthorBookScreen";
import AuthorChapterScreen from "./screens/AuthorChapterScreen";
import AuthorLinkScreen from "./screens/AuthorLinkScreen";

const Stack = createNativeStackNavigator();

export default class App extends React.Component {

    render() {

        return (
            <NavigationContainer>
                <Stack.Navigator>
                    <Stack.Screen name="StartScreen" component={StartScreen} options={{ title: "Login" }} />
                    <Stack.Screen name="RegisterScreen" component={RegisterScreen} options={{ title: "Register" }} />
                    <Stack.Screen name="LoginScreen" component={LoginScreen} options={{ title: "Login" }} />
                    <Stack.Screen name="BookOverviewScreen" component={BookOverviewScreen} options={{ title: "Book Overview" }} />
                    <Stack.Screen name="OverviewScreen" component={OverviewScreen} options={{ title: "Overview" }} />
                    <Stack.Screen name="ReadScreen" component={ReadScreen} options={{ title: "" }} />
                    <Stack.Screen name="AuthorBookScreen" component={AuthorBookScreen} options={{ title: "Author Book" }} />
                    <Stack.Screen name="AuthorChapterScreen" component={AuthorChapterScreen} options={{ title: "Author Chapter" }} />
                    <Stack.Screen name="AuthorLinkScreen" component={AuthorLinkScreen} options={{ title: "Author Link" }} />
                </Stack.Navigator>
            </NavigationContainer>
        );
    }
}

