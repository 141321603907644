import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { LinkModel } from "../models/linkModel";


export type Props = {
    item: LinkModel,
    key: number,
    onPress?: Function
}

export type State = {
}

export default class Link extends React.Component<Props, State> {

    render() {
        return (
            <TouchableOpacity style={styles.container} onPress={() => {
                if (this.props.onPress) {
                    this.props.onPress(this.props.item);
                }
            }}>
                <Text style={styles.text}>{this.props.item.label}</Text>
            </TouchableOpacity>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        padding: 10,
        backgroundColor: "rgb(33, 150, 243)",
        margin: 5
    },
    text: {
        color: "white",
        fontSize: 16
    }
});

