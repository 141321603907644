import * as React from 'react';
import { LinkModel } from '../models/linkModel';
import { BookOverviewModel } from '../models/bookOverviewModel';
import { CurrentReadModel } from '../models/currentReadModel';
import { Alert2 } from '../helpers/alert2';
import { ResponseBaseRecord, ResponseBase } from '../models/responseBase';
import { Text, View, StyleSheet, Button, FlatList, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import BookOverviewScreen from './BookOverviewScreen';
import OverviewScreen from './OverviewScreen';
import { LinkModelWithTargetChapterIndex } from '../models/linkModelWithTargetChapterIndex';
import ReadLinkList from '../components/ReadLinkList';
import ReadContent from '../components/ReadContent';

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
    readIndex: number;
    linkIndex: number | undefined;
    chapterIndex: number | undefined;
    currentReadModel: CurrentReadModel;
    bookOverview: BookOverviewModel;
}

export default class ReadScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        isRefreshing: false,
        isInitialRefreshDone: null,
        readIndex: this.props.route.params.readIndex,
        linkIndex: this.props.route.params.linkIndex,
        chapterIndex: this.props.route.params.chapterIndex,
        currentReadModel: null,
        bookOverview: this.props.route.params.bookOverview
    }

    public get areLinkButtonsEnabled(): boolean {

        if (!this.state.currentReadModel) {
            return false;
        }

        if (this.state.currentReadModel.plan == 0 && this.state.currentReadModel.remainingTrialChapterCount == 0) {
            return false;
        }

        return true;
    }



    constructor(props: Props) {
        super(props);

        //console.warn(this.props);
        //console.warn(this.state);

    }



    componentDidMount() {

        console.log("[ReadScreen] componentDidMount");

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {
        console.log("[ReadScreen] componentWillUnmount");
    }

    public linkItemPressed(item: LinkModel) {

        this.props.navigation.replace("ReadScreen", {
            accessToken: this.state.accessToken,
            bookOverview: this.state.bookOverview,
            readIndex: this.state.readIndex,
            linkIndex: item.index
        });

        if (BookOverviewScreen.refresh) {
            BookOverviewScreen.refresh();
        }

    }

    public refreshData() {

        //console.info('[ReadScreen] refreshData');

        var url = "";

        if (this.state.chapterIndex) {
            url = `https://arcanechapters.com/read/${this.state.bookOverview.book.moniker}/chapter/${this.state.chapterIndex}`;
        } else if (!this.state.readIndex) {
            url = `https://arcanechapters.com/read/${this.state.bookOverview.book.moniker}/new`;
        } else if (this.state.linkIndex) {
            url = `https://arcanechapters.com/read/${this.state.bookOverview.book.moniker}/link/${this.state.linkIndex}`;
        } else if (this.state.readIndex) {
            url = `https://arcanechapters.com/read/${this.state.bookOverview.book.moniker}/continue/${this.state.readIndex}`;
        } else {
            return;
        }

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.state.accessToken
            })
        }).then((result) => result.json()).then((result) => {
            this.refreshDataCallback(result);
        }).catch((error) => {
            Alert2.alert(error);
        });
    }

    public refreshDataCallback(response: ResponseBaseRecord<CurrentReadModel>) {

        console.info("[ReadScreen]", response);

        if (response.success) {
            if (!this.state.readIndex || this.state.linkIndex) {

                if (OverviewScreen.refresh) {
                    OverviewScreen.refresh();
                }

                if (BookOverviewScreen.refresh) {
                    BookOverviewScreen.refresh();
                }

            }

            this.props.navigation.setOptions({
                title: response.record.chapter.title
            });

            this.setState({
                isRefreshing: false,
                isInitialRefreshDone: true,
                currentReadModel: response.record,
                readIndex: response.record.readIndex,
                linkIndex: null
            });

        } else {
            setTimeout(() => {
                Alert2.alert(response.errorCode);
            });
        }
    }

    public renderLinkItem({ item, index }: { item: LinkModel, index: number }) {

        return (
            <Button title={item.label}
                onPress={() => {
                    this.linkItemPressed(item);
                }}
                disabled={!this.areLinkButtonsEnabled}
            />
        );
    }

    render() {

        //console.info('[ReadScreen] render');
        //console.info(this.state);

        //if (!this.state.isInitialRefreshDone) {
        //    this.refreshData();
        //}

        return (
            <SafeAreaView style={styles.container}>
                <ReadContent currentReadModel={this.state.currentReadModel} onLinkPress={(item: LinkModel) => {
                    this.linkItemPressed(item);
                }} />
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        backgroundColor: "white",
        justifyContent: "center"
    },
    scrollView: {
        flex: 1,
        display: "flex",
        backgroundColor: "rgb(196, 164, 132)"
    },
    listItem: {
        backgroundColor: "#C4A484",
        display: "flex",
        padding: 10,
        margin: 3
    },
    view: {
        flex: 1,
    },
    readContentText: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        padding: 10,
        fontSize: 18,
        textAlign: "justify"
    },
    link: {

    },
    links: {

    },
    buttonsFlatList: {
        overflow: "hidden",
        flexShrink: 0
    },
    listTtemTitle: {
        fontSize: 14,
        fontWeight: "bold"
    },
    listItemDescription: {
        fontSize: 12,
        paddingLeft: 3
    }
});
