import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { ChapterModelWithLinks } from "../models/chapterModelWithLinks";
import { LinkModelWithTargetChapterIndex } from "../models/linkModelWithTargetChapterIndex";
import AuthorLink from "./AuthorLink";


export type Props = {
    items: LinkModelWithTargetChapterIndex[],
    onItemPress?: Function
}

export type State = {
}

export default class AuthorLinkList extends React.Component<Props, State> {

    render() {

        let children: JSX.Element[] = [];


        if (this.props.items) {

            this.props.items.map((item, index) => {
                children.push(<AuthorLink key={index + 1} item={item} onPress={(item) => {
                    if (this.props.onItemPress) {
                        this.props.onItemPress(item);
                    }
                }}></AuthorLink>)
            });

            children.push(<AuthorLink key={0} onPress={(item) => {
                if (this.props.onItemPress) {
                    this.props.onItemPress(item);
                }
            }}></AuthorLink>)
        }

        return (
            <View style={styles.linksView} >
                <Text style={styles.boldText}>Links:</Text>
                <View children={children}></View>
            </View>
        );

    }

}

const styles = StyleSheet.create({
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    boldText: {
        fontWeight: "bold"
    },
    linksView: {
    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

