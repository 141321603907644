import AsyncStorage from '@react-native-async-storage/async-storage';

class Storage {

    public static storeData = async (key: string, value: string) => {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (e) {
            // saving error
        }
    };

    public static getData = async (key: string) => {
        try {
            const value = await AsyncStorage.getItem(key);
            return value;
        } catch (e) {
            // error reading value
        }
    };

    public static clearStorage = async () => {
        try {
            await AsyncStorage.clear();
        } catch (e) {
            // error reading value
        }
    };

}

export { Storage }
