import * as React from 'react';
import { Text, View, StyleSheet, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookOverviewModel } from '../models/bookOverviewModel';
import { BookModel } from '../models/bookModel';
import { ResponseBaseRecord, ResponseBase } from '../models/responseBase';
import { ReadModel } from '../models/readModel';
import { AccountBookModel } from '../models/accountBookModel';
import OverviewScreen from './OverviewScreen';
import ReadPurchaseButtons from '../components/ReadPurchaseButtons';
import ReadButton from '../components/ReadIndexButton';
import ReadButtonList from '../components/ReadButtonList';
import { Alert2 } from '../helpers/alert2';

export type Props = {
    navigation: any;
    route: any;
}

export type State = {
    accessToken: string;
    isRefreshing: boolean;
    isInitialRefreshDone: boolean;
    bookOverview: BookOverviewModel;
    book: BookModel;
}

export default class BookOverviewScreen extends React.Component<Props, State> {

    state = {
        accessToken: this.props.route.params.accessToken,
        isRefreshing: false,
        isInitialRefreshDone: false,
        bookOverview: null as BookOverviewModel,
        book: this.props.route.params.book as BookModel
    }

    public static refresh: Function;

    public get isAuthoredBook(): boolean {

        if (this.state.bookOverview && this.state.bookOverview.accountBook && this.state.bookOverview.accountBook.plan == 3) {
            return true;
        }


        return false;
    }
    public get isStartTrialButtonVisible(): boolean {

        if (!this.state.bookOverview) return false;
        if (this.state.bookOverview.accountBook) return false;
        if (!this.state.bookOverview.book.trialChapterLimit) return false;
        if (this.state.bookOverview.book.trialChapterLimit === 0) return false;

        return true;
    }
    public get isPurchaseOneShotButtonVisible(): boolean {

        if (!this.state.bookOverview) return false;
        if (this.state.bookOverview && this.state.bookOverview.accountBook) {
            if (this.state.bookOverview.accountBook.plan == 2) return false;
            if (this.state.bookOverview.accountBook.plan == 3) return false;
        }

        return true;
    }
    public get isPurchaseUnlockedButtonVisible(): boolean {

        if (!this.state.bookOverview) return false;
        if (this.state.bookOverview && this.state.bookOverview.accountBook) {
            if (this.state.bookOverview.accountBook.plan == 2) return false;
            if (this.state.bookOverview.accountBook.plan == 3) return false;
        }

        return true;
    }



    constructor(props: any) {

        super(props);

        //BookOverviewScreen.refresh = () => {
        //    this.refreshData();
        //}

        this.updateTitle();

        //if (!this.state.isInitialRefreshDone) {
        //    this.refreshData();
        //}

        //this.props.navigation.addListener('focus', () => {});
        //this.props.navigation.addListener('blur', () => {
        //});
    }



    componentDidMount() {

        console.log("[BookOverviewScreen] componentDidMount");

        BookOverviewScreen.refresh = () => {
            this.refreshData();
        }

        if (!this.state.isInitialRefreshDone) {
            this.refreshData();
        }
    }

    componentWillUnmount() {

        console.log("[BookOverviewScreen] componentWillUnmount");
        BookOverviewScreen.refresh = null;
    }

    public clearAllReads() {

        if (!this.state.bookOverview) return;
        if (!this.state.bookOverview.accountBook) return;
        if (this.state.bookOverview.accountBook.plan != 3) return;

        Alert2.confirm("Confirm Delete", "Are you sure you want clear all reads?", "Delete", () => {
            this.clearAllReadsConfirmed();
        }, "Cancel", () => {
        });

    }

    public clearAllReadsConfirmed() {

        this.setState({
            isRefreshing: true,
        });

        var url = `https://arcanechapters.com/author/${this.state.book.moniker}/clearallreads`;

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: this.state.accessToken,
            }),
        }).then((res) => res.json()).then((result) => {
            this.clearAllReadsCallback(result);
        });

    }

    public clearAllReadsCallback(response: ResponseBaseRecord<BookOverviewModel>) {

        if (response && response.success) {

            this.setState({
                isRefreshing: false,
                bookOverview: response.record,
            });

            if (OverviewScreen.refresh) {
                OverviewScreen.refresh();
            }

            console.info(response);

        }

    }

    public purchaseOneShot() {

        this.setState({
            isRefreshing: true,
        });

        var url = `https://arcanechapters.com/purchase/${this.state.book.moniker}/oneshot`;

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: this.state.accessToken,
            }),
        }).then((res) => res.json()).then((result) => {
            this.purchaseCallback(result);
        });
    }

    public purchaseUnlocked() {

        this.setState({
            isRefreshing: true,
        });

        var url = `https://arcanechapters.com/purchase/${this.state.book.moniker}/unlocked`;

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: this.state.accessToken,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                this.purchaseCallback(result);
            });
    }

    public purchaseCallback(response: ResponseBaseRecord<BookOverviewModel>) {

        this.setState({
            isRefreshing: false,
            bookOverview: response.record,
        });

        if (OverviewScreen.refresh) {
            OverviewScreen.refresh();
        }

        console.info(response);
    }

    public readButtonPressed(readIndex: number) {

        this.props.navigation.navigate("ReadScreen", {
            accessToken: this.state.accessToken,
            bookOverview: this.state.bookOverview,
            readIndex: readIndex
        });

    }

    public refreshData() {
        //console.info('[BookOverviewScreen] refreshData');

        fetch(`https://arcanechapters.com/overview/${this.state.book.moniker}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: this.state.accessToken }),
        }).then((res) => res.json()).then((result) => {
            this.refreshDataCallback(result);
        });
    }

    public refreshDataCallback(response: ResponseBaseRecord<BookOverviewModel>) {

        console.info('[BookOverviewScreen]', response);

        this.setState({
            isRefreshing: false,
            isInitialRefreshDone: true,
            bookOverview: response.record,
        });
    }

    public updateTitle() {
        this.props.navigation.setOptions({ title: this.state.book.title });
    }

    render() {

        //console.info('[BookOverviewScreen] render');

        return (
            <SafeAreaView style={styles.container}>
                <ScrollView style={styles.containerView}>
                    <Text style={styles.bookDescription}>{this.state.book.description}</Text>
                    <ReadButtonList onButtonPress={(readIndex: number) => {
                        this.readButtonPressed(readIndex);
                    }} bookOverview={this.state.bookOverview} />
                    <ReadPurchaseButtons isScreenRefreshing={this.state.isRefreshing}
                        currentOneShotPrice={this.state.book.currentOneShotPrice}
                        currentFullPrice={this.state.book.currentFullPrice}
                        isPurchaseOneShotButtonVisible={this.isPurchaseOneShotButtonVisible}
                        isPurchaseUnlockedButtonVisible={this.isPurchaseUnlockedButtonVisible}
                        onPurchaseOneShotPress={() => { this.purchaseOneShot(); }}
                        onPurchaseUnlockedPress={() => { this.purchaseUnlocked(); }}
                        isAuthoredBook={this.isAuthoredBook}
                        onClearAllReadsPress={() => {
                            this.clearAllReads();
                        }}
                    />
                </ScrollView>
            </SafeAreaView>
        );
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        backgroundColor: "white",
        display: "flex"
    },
    containerView: {
        display: "flex",
        backgroundColor: "white",
        flex: 1
    },
    readItemContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 5,
        flex: 1
    },
    bookDescription: {
        fontSize: 20,
        padding: 10,
        fontWeight: "bold"
    },
    readItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    readItemTrial: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    readItemTrialUnread: {
        backgroundColor: "#c4c4ff"
    },
    readItemNotUsedOneShot: {
        backgroundColor: "orange",
        color: "black"
    },
    readItemNotUsedOneShotUnread: {
        backgroundColor: "#ffd280"
    },
    readItemUnlocked: {
        backgroundColor: "green",
        color: "black"
    },
    readItemUnlockedUnread: {
        backgroundColor: "#80c080"
    },
    readIconText: {
        fontSize: 16
    }
})
