import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";
import { LinkModelWithTargetChapterIndex } from "../models/linkModelWithTargetChapterIndex";


export type Props = {
    item?: LinkModelWithTargetChapterIndex,
    key: number,
    isAddNewItem?: boolean,
    onPress?: Function
}

export type State = {
}

export default class AuthorLink extends React.Component<Props, State> {

    render() {

        let styleArray: any[] = [];
        let labelStyleArray: any[] = [styles.linkItemLabel];

        let text = "[add new link]"
        styleArray.push(styles.linkItem);

        if (this.props.item && !this.props.item.targetChapterIndex) {
            text = `${this.props.item.index} - ${this.props.item.label} [no target]`;
            //styleArray.push(styles.linkItemNoTarget);
            labelStyleArray.push(styles.linkItemLabel_invalid);
        }
        else if (!this.props.isAddNewItem && this.props.item) {
            text = `${this.props.item.index} - ${this.props.item.label}`;
        }
        else {
            styleArray.push(styles.linkItemAddNew);
            labelStyleArray.push(styles.linkItemLabel_addNew);
        }



        return (
            <TouchableOpacity style={styleArray} onPress={() => {
                if (this.props.onPress) {
                    this.props.onPress(this.props.item);
                }
            }}>
                <Text style={labelStyleArray}>{text}</Text>
            </TouchableOpacity>
        );

    }

}

const styles = StyleSheet.create({
    chapterItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    chapterItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    chapterItemLabel: {
        color: "white"
    },
    chapterItemLabel_addNew: {

    },
    chapterItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    },
    linkItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    linkItemAddNew: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    linkItemNoTarget: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    linkItemLabel: {
        color: "white"
    },
    linkItemLabel_addNew: {

    },
    linkItemLabel_invalid: {
        color: "red",
        fontWeight: "bold"
    }
});

