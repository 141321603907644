import * as React from "react";
import { Text, View, StyleSheet, ActivityIndicator, Button, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native';
import { AccountBookModel } from "../models/accountBookModel";
import { BookModel } from "../models/bookModel";
import { ChapterModelWithIndex } from "../models/chapterModelWithIndex";


export type Props = {
    readIndex?: number,
    currentChapterTitle?: string,
    isFinished?: boolean,
    iconType?: "trial" | "oneShot" | "unlocked",
    key: number,
    onPress?: Function,
    accountBook: AccountBookModel
}

export type State = {
}

export default class ReadIndexButton extends React.Component<Props, State> {

    render() {

        var styleArray: any[] = [styles.readItem];

        switch (this.props.iconType) {
            case "trial":
                if (this.props.readIndex == null || (this.props.accountBook as AccountBookModel &&
                    this.props.accountBook.remainingTrialChapterCount == 0)) {
                    styleArray.push(styles.readItemTrialUnread);
                }
                else {
                    styleArray.push(styles.readItemTrial);
                }
                break;

            case "oneShot":
                if (this.props.readIndex == null || this.props.isFinished) styleArray.push(styles.readItemNotUsedOneShotUnread);
                else styleArray.push(styles.readItemNotUsedOneShot);
                break;

            case "unlocked":
                if (this.props.readIndex == null || this.props.isFinished) styleArray.push(styles.readItemUnlockedUnread);
                else styleArray.push(styles.readItemUnlocked);
                break;
        }

        var label = "[start new read]";

        if (this.props.readIndex) {
            label = this.props.readIndex + " - " + this.props.currentChapterTitle;
            if (this.props.isFinished) {
                label += " [finished]";
            } else if (this.props.iconType == "trial" && this.props.accountBook && this.props.accountBook.remainingTrialChapterCount == 0) {
                label += " [out of trial chapters]";
            }
        }

        return (
            <TouchableOpacity
                style={styleArray}
                onPress={() => {
                    if (this.props.onPress) {
                        this.props.onPress(this.props.readIndex);
                    }
                }}>
                <Text style={styles.readIconText}>{label}</Text>
            </TouchableOpacity>
        );

    }

}

const styles = StyleSheet.create({
    readItem: {
        display: "flex",
        borderColor: "black",
        padding: 10,
        backgroundColor: "#666688",
        fontWeight: "bold",
        margin: 3
    },
    readItemTrial: {
        backgroundColor: "#8888ff",
        color: "black"
    },
    readItemTrialUnread: {
        backgroundColor: "#c4c4ff"
    },
    readItemNotUsedOneShot: {
        backgroundColor: "orange",
        color: "black"
    },
    readItemNotUsedOneShotUnread: {
        backgroundColor: "#ffd280"
    },
    readItemUnlocked: {
        backgroundColor: "green",
        color: "black"
    },
    readItemUnlockedUnread: {
        backgroundColor: "#80c080"
    },
    readIconText: {
        fontSize: 16
    }
});

